










































































































































































import { Vue, Component } from 'vue-property-decorator'
import { vxm } from '@/store'

@Component({
  computed: {
    totalCoin: {
      get() {
        return vxm.userTransaction.userTotalCoin
      },
    },
  },
})
export default class extends Vue {
  private studentId = ''
  private subjDialog = false
  private subject = ''
  private isMn = false
  private summaryDialog = false
  private onCheckout = false

  private get subjString() {
    switch (this.subject) {
      case '1':
        return 'MATH'
      case '2':
        return 'PHYSICS'
      case '3':
        return 'CHEM'
      case '4':
        return 'ENGLISH'
      default:
        return '-'
    }
  }

  private submit() {
    const reg = /^\d{5}$/
    if (reg.test(this.studentId)) {
      this.subjDialog = true
    } else {
      this.studentId = ''
    }
  }

  private async checkout() {
    this.onCheckout = true
    this.summaryDialog = true
    if (this.isMn) {
      await vxm.userTransaction.createFhbMnTransaction(
        `${this.studentId}${this.subject}`
      )
    } else {
      await vxm.userTransaction.createFhbTransaction(
        `${this.studentId}${this.subject}`
      )
    }
    this.onCheckout = false
    setTimeout(() => {
      this.reset()
    }, 1500)
  }

  private reset() {
    this.studentId = ''
    this.subject = ''
    this.isMn = false
    this.subjDialog = false
    this.summaryDialog = false
    this.$nextTick(() => {
      const ref = this.$refs.inputBox as HTMLInputElement
      ref.focus()
    })
  }
}
