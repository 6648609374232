var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-main',[_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-img',{attrs:{"alt":"logo","src":require('@/assets/monkey-logo.png'),"width":"100%","max-width":"400","contain":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-text-field',{ref:"inputBox",attrs:{"color":"opposite","outlined":"","autofocus":""},model:{value:(_vm.studentId),callback:function ($$v) {_vm.studentId=$$v},expression:"studentId"}})],1)],1)],1),_c('v-dialog',{attrs:{"width":"768px","persistent":""},model:{value:(_vm.subjDialog),callback:function ($$v) {_vm.subjDialog=$$v},expression:"subjDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("เลือกวิชา")]),_c('v-container',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"title",attrs:{"fab":"","width":"128","height":"128","color":"nursery","depressed":""},on:{"click":function($event){_vm.subject = '1'
                    _vm.isMn = true
                    _vm.checkout()}}},[_vm._v(" MN ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"title",attrs:{"fab":"","width":"128","height":"128","color":"math","depressed":""},on:{"click":function($event){_vm.subject = '1'
                    _vm.isMn = false
                    _vm.checkout()}}},[_vm._v(" MATH ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"title",attrs:{"fab":"","width":"128","height":"128","color":"phys","depressed":""},on:{"click":function($event){_vm.subject = '2'
                    _vm.isMn = false
                    _vm.checkout()}}},[_vm._v(" PHYS ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"title",attrs:{"fab":"","width":"128","height":"128","color":"chem","depressed":""},on:{"click":function($event){_vm.subject = '3'
                    _vm.isMn = false
                    _vm.checkout()}}},[_vm._v(" CHEM ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"title",attrs:{"fab":"","width":"128","height":"128","color":"engl","depressed":""},on:{"click":function($event){_vm.subject = '4'
                    _vm.isMn = false
                    _vm.checkout()}}},[_vm._v(" ENG ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"title",attrs:{"fab":"","width":"128","height":"128","color":"engl","depressed":""},on:{"click":function($event){_vm.subject = '4'
                    _vm.isMn = true
                    _vm.checkout()}}},[_vm._v(" EN ")])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"450px","persistent":""},model:{value:(_vm.summaryDialog),callback:function ($$v) {_vm.summaryDialog=$$v},expression:"summaryDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.studentId)+" : "+_vm._s(_vm.subjString))]),_c('v-container',[(_vm.onCheckout)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-progress-circular',{attrs:{"color":"secondary","size":"128","width":"12","indeterminate":""}})],1):[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"subtitle-1",attrs:{"cols":"auto"}},[_vm._v("คงเหลือ")])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"display-2",attrs:{"cols":"auto"}},[_vm._v(_vm._s(_vm.totalCoin / 800))])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"subtitle-1",attrs:{"cols":"auto"}},[_vm._v("เหรียญ")])],1)]],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }